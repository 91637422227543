import { gql } from '@apollo/client';
import { useMutation, useMyUrl, useRootStore } from '@gimlite/watermelon';
import { Wizard } from '@gimlite/watermelon/components/wizard/wizard.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  Mutation,
  MutationCreateFpsArgs,
  Query,
} from '../../../client/graphql';
import { BilanStep } from './steps/bilan/bilan.step';
import { ConfirmStep } from './steps/confirm/confirm.step';
import { InfoStep } from './steps/info/info.step';

export type CreateFPSWizardContext = {
  sessionId: string;
  upsId: string;
  type: 'INITIAL';
  userId: string;
  rootFineLegalId: string;
  terminalId: string;
  lpn: string | null;
  country: string | null;
  countryISO: string | null;
  brand: string | null;
  model: string | null;
  parkId: string;
  statementDatetime: string;
  comment: string | null;
  entry: {
    camera: string;
    date: string;
  };
  exit: {
    camera: string;
    date: string;
  };
  confirm: {
    right: boolean;
    claim: boolean;
  };
  claimReasons: string[];
  address: string;
  paymentStatus: 'PENDING';
  pictures: Array<{
    mimeType: string;
    description: string;
    pictureDatetime: string;
    contentURL: string;
  }>;
  confirm0: boolean;
  confirm1: boolean;
};

export const CreateFPSWizard = observer(() => {
  const { t } = useTranslation();
  const { AuthStore, WizardStore } = useRootStore();
  const { id } = useParams();
  const { getParamsUrl } = useMyUrl();
  const me = AuthStore.user as Query['me'];
  const { fps } = WizardStore.selected?.store as any;
  const [createFpsCall, createFpsCallState] = useMutation<
    {
      createFps: Mutation['createFps'];
    },
    MutationCreateFpsArgs
  >(gql`
    mutation createFps($input: CreateFpsDto!) {
      createFps(input: $input) {
        fineId
      }
    }
  `);

  const state = useMemo(() => {
    if (createFpsCallState.loading) return undefined;
    if (createFpsCallState.error) return false;
    if (createFpsCallState.data) return true;
  }, [createFpsCallState]);

  return (
    <Wizard<CreateFPSWizardContext>
      config={{
        title: `${t('createFps')} (${fps.lpn})`,
        withValidation: {
          state,
          success: t('CREATE_FPS_SUCCESS'),
          error: t('CREATE_FPS_ERROR'),
        },
      }}
      data={{
        defaultContext: {
          sessionId: fps.sessionId,
          upsId: fps.upsId,
          type: 'INITIAL',
          userId: me._id!,
          rootFineLegalId: '',
          terminalId: fps.terminalId,
          lpn: null,
          country: null,
          countryISO: null,
          brand: null,
          model: null,
          parkId: fps.parkId,
          statementDatetime: fps.statementDatetime,
          comment: null,
          entry: fps.entry,
          exit: fps.exit,
          address: fps.address,
          confirm: fps.confirm,
          claimReasons: [],
          pictures: [
            {
              mimeType: 'image/jpeg',
              description: 'entry',
              pictureDatetime: fps.entry.date,
              contentURL: fps.entry.camera,
            },
            {
              mimeType: 'image/jpeg',
              description: 'exit',
              pictureDatetime: fps.exit.date,
              contentURL: fps.exit.camera,
            },
          ],
          paymentStatus: 'PENDING',
          confirm0: false,
          confirm1: false,
        },
      }}
      handleEvent={{
        done: async ({
          sessionId,
          upsId,
          type,
          userId,
          rootFineLegalId,
          terminalId,
          lpn,
          countryISO,
          brand,
          model,
          parkId,
          statementDatetime,
          comment,
          pictures,
          paymentStatus,
        }) => {
          await createFpsCall({
            variables: {
              input: {
                sessionId,
                upsId,
                type,
                userId,
                rootFineLegalId,
                terminalId,
                licensePlate: {
                  plate: lpn!,
                  plateCountry: countryISO!,
                },
                vehicle: {
                  brand: brand!,
                  model: model || '',
                },
                statementDatetime,
                comment: comment || '',
                pictures,
                paymentStatus,
              },
            },
          });
        },
        clear: () => {},
        close: () => {
          WizardStore.clear();
        },
      }}
    >
      {fps?.confirm && Object.values(fps?.confirm).includes(true) && (
        <Wizard.Item
          config={{ title: t('confirmation'), component: ConfirmStep }}
        />
      )}
      <Wizard.Item config={{ title: t('informations'), component: InfoStep }} />
      <Wizard.Item config={{ title: t('summary'), component: BilanStep }} />
    </Wizard>
  );
});
