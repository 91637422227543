import { useRootStore } from '@gimlite/watermelon';
import { observer } from 'mobx-react-lite';
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from 'react-router-dom';
import MainUse from './main';
import { Events } from './pages/events/view/events.page';
import { LoginPage } from './pages/login/view/login.page';
import { Occupancy } from './pages/occupancy/view/occupancy.page';
import { Vao } from './pages/vao/view/vao.page';

export const CustomRouter = observer(() => {
  const { AuthStore } = useRootStore();

  const guestPathToPage = [
    {
      path: '*',
      element: <Navigate to={`/`} replace />,
    },
    {
      path: '/',
      element: <LoginPage />,
    },
  ];

  const userPathToPage = [
    {
      path: '/',
      element: <MainUse />,
      children: [
        {
          path: ':id',
          element: <Outlet />,
          children: [
            {
              index: true,
              element: <Events />,
            },
            {
              path: 'events',
              element: <Events />,
            },
            {
              path: 'vao',
              element: <Vao />,
            },
            {
              path: 'occupancy',
              element: <Occupancy />,
            },
          ],
        },
      ],
    },
  ];

  return (
    <RouterProvider
      router={createBrowserRouter(
        AuthStore.token ? userPathToPage : guestPathToPage,
      )}
    ></RouterProvider>
  );
});
