import {
  AppLoaderEnd,
  AppLoaderStart,
  ConfigAnt,
  ConfigApp,
  ConfigAuth,
  ConfigGQL,
  ConfigI18N,
  ConfigMenu,
  ConfigNotification,
  ConfigRoot,
  ConfigSocket,
  ConfigTheme,
  ConfigWizard,
} from '@gimlite/watermelon';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { CustomRouter } from './CustomRouter';
import IEMSmallLogo from './assets/logo/IEM_small';
import config from './config';
import { CreateFPSWizard } from './wizards/createFps/createFps.wizard';

const root = createRoot(document.getElementById('root') as HTMLElement);

const { ENV, VERSION_FRONT, BFF_HOST, BFF_WS_HOST, BFF_TOKEN } = config;

const defaultTheme = 'light';

root.render(
  <React.StrictMode>
    <ConfigRoot>
      <AppLoaderStart config={{ logo: IEMSmallLogo(), defaultTheme }}>
        <ConfigApp
          version={{ env: ENV, front: VERSION_FRONT }}
          favicon={
            'https://cellar-c2.services.clever-cloud.com/yoonite-resources/default/presto-scan.svg'
          }
        >
          <ConfigGQL
            uri={BFF_HOST}
            headers={{
              authorization: `Bearer ${BFF_TOKEN}`,
            }}
          >
            <ConfigAuth features={{ updatePassword: true }}>
              <ConfigSocket uri={BFF_WS_HOST}>
                <ConfigI18N>
                  <ConfigWizard
                    registry={[
                      {
                        id: 'createFps',
                        component: CreateFPSWizard,
                      },
                    ]}
                  >
                    <ConfigMenu>
                      <ConfigNotification>
                        <ConfigTheme
                          color={{
                            dark: '#5dbed2',
                            light: '#0a2652',
                          }}
                          defaultTheme={defaultTheme}
                        >
                          <ConfigAnt>
                            <AppLoaderEnd>
                              <CustomRouter />
                            </AppLoaderEnd>
                          </ConfigAnt>
                        </ConfigTheme>
                      </ConfigNotification>
                    </ConfigMenu>
                  </ConfigWizard>
                </ConfigI18N>
              </ConfigSocket>
            </ConfigAuth>
          </ConfigGQL>
        </ConfigApp>
      </AppLoaderStart>
    </ConfigRoot>
  </React.StrictMode>,
);
