import { useRootStore } from '@gimlite/watermelon';
import { Button } from '@gimlite/watermelon/components/button/button.component';
import { Empty } from '@gimlite/watermelon/components/empty/empty.component';
import { Page } from '@gimlite/watermelon/components/page/page.component';
import { Statistic } from '@gimlite/watermelon/components/statistic/statistic.component';
import { Table } from '@gimlite/watermelon/components/table/table.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Query } from '../../../../client/graphql';
import './occupancy.page.scss';

export const Occupancy = observer(() => {
  const { AuthStore } = useRootStore();
  const me = AuthStore.user as Query['me'];

  const { id } = useParams();

  const currentUps = useMemo(() => {
    const findUps: any = me?.ups?.find(({ _id }: any) => _id === id);
    if (!findUps && (!findUps?.occupancy || !findUps?.capacity)) return null;
    return findUps;
  }, [me, id]);

  return (
    <Page className="occupancy">
      {currentUps && (
        <Zone
          config={{
            zones: [
              ['occupancy', 'capacity', 'freeSpaces', 'table'],
              ['graph', 'graph', 'graph', 'table'],
            ],
            rows: ['min-content', '1fr'],
            columns: ['min-content', 'min-content', 'min-content', '1fr'],
          }}
        >
          <Zone.Area config={{ area: 'occupancy' }}>
            <Widget
              config={{
                action: [
                  <Button
                    handleEvent={{
                      click: () => {
                        console.log('click');
                      },
                    }}
                    config={{ color: 'primary', text: 'Modifier' }}
                  ></Button>,
                ],
                title: 'Occupation',
              }}
            >
              <Statistic
                data={{
                  value: currentUps.occupancy >= 0 ? currentUps.occupancy : 0,
                }}
                config={{ explain: 'Mise à jour il y a 30 secondes' }}
              />
            </Widget>
          </Zone.Area>
          <Zone.Area config={{ area: 'capacity' }}>
            <Widget config={{ title: 'Capacité' }}>
              <Statistic
                data={{
                  value: currentUps.capacity,
                }}
                config={{ explain: 'Mise à jour il y a 30 secondes' }}
              />
            </Widget>
          </Zone.Area>
          <Zone.Area config={{ area: 'freeSpaces' }}>
            <Widget config={{ title: 'Nombre de places libres' }}>
              <Statistic
                data={{
                  value:
                    currentUps.capacity - currentUps.occupancy >= 0
                      ? currentUps.capacity - currentUps.occupancy
                      : 0,
                }}
                config={{ explain: 'Mise à jour il y a 30 secondes' }}
              />
            </Widget>
          </Zone.Area>
          <Zone.Area config={{ area: 'table' }}>
            <Widget config={{ title: "Historique de l'occupation" }}>
              <Table<any>
                handleEvent={{
                  paging: (value) => {
                    // dispatch('SEARCH_EVENTS', searchRequest);
                  },
                }}
                data={{
                  list: Array.from({ length: 100 }).map((value, index) => ({
                    _id: `${index}`,
                    createdAt: '15/05/2023 08:25:14',
                    freeSpaces: '14',
                    occupancy: '42',
                  })),

                  paging: { count: 1, current: 1, limit: 300 },
                }}
                config={{
                  alternateColor: true,
                  actions: {
                    read: true,
                  },
                  pagination: 'scroll',
                  columns: [
                    {
                      title: 'Date',
                      key: 'createdAt',
                    },
                    {
                      title: 'Places libre',
                      key: 'freeSpaces',
                    },
                    {
                      title: 'Occupancy',
                      key: 'occupancy',
                    },
                  ],
                }}
              />
            </Widget>
          </Zone.Area>
          <Zone.Area config={{ area: 'graph' }}>
            <Widget config={{ title: "Graphique de l'occupation" }}>
              <Empty config={{ mode: { name: 'commingSoon' } }}></Empty>
            </Widget>
          </Zone.Area>
        </Zone>
      )}
    </Page>
  );
});
